<template>
    <div class="space-y-4 px-4 md:px-6 py-2">
        <div class="mb-3 flex flex-col justify-center items-center">
            <div class="text-stone-700 w-full text-xl font-medium">
                Комплексная годовая программа для ребенка
            </div>
        </div>

        <div class="flex justify-between items-center">
            <div class="block text-left text-sm text-xl font-medium">
                {{ programsStore.program }} {{ programsStore.age }}
            </div>
            <div class="flex items-center text-xl font-medium" v-if="programsStore.home">
                <span class="mr-2 text-base text-black/40">на дому</span>
            </div>
        </div>

        <div class="flex flex-wrap gap-2 w-full justify-between border-gray-300 border rounded-md p-4 hidden">
            <div class="">
                <p class="m-0" v-if="!programsStore.home">Итоговая стоимость</p>
                <p class="m-0" v-else>Предварительная стоимость</p>
                <p class="mt-1 text-pink-600 max-w-[235px] text-sm">
                    включена дополнительная скидка 10% на амбулаторные услуги педиатрии в период действия программы
                </p>
                <p
                    class="mt-1 text-pink-600 max-w-[235px] text-sm"
                    v-if="programsStore.home"
                >
                    Итоговая стоимость программы зависит от адреса проживания
                </p>
            </div>
            <div class="block">
                <div class="text-xl leading-8">
                    {{ numberWithSpaces(programsStore.programCost) }} ₽
                </div>
            </div>
        </div>

        <div class="mt-6" v-if="docsList.length">
            <div class="mb-2">Выберите ведущего врача</div>

            <div v-for="doc in docsList" :key="doc.id"
                 class="flex rounded-md mb-2 p-2 cursor-pointer border items-center hover:bg-pink-50"
                 :class="doc.id !== programsStore.leadDoc ? 'border-gray-300' : 'border-pink-300 border-2'">
                <div class="flex flex-wrap items-center justify-start w-full" @click="selectDoc(doc.id)">
                    <div class="mr-2 rounded-full w-12 h-12 max-w-12 overflow-hidden border-4 border-white">
                        <img v-if="doc?.attributes?.photo_mini?.data?.attributes?.url"
                             :src="`https://admin.fomin-kids.ru${doc.attributes.photo_mini.data.attributes.url}`"
                             :alt="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             :title="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             class="w-full h-full object-cover object-top" />
                        <img v-else :src="defaultPhoto" class="w-full h-full object-cover object-top" />
                    </div>
                    <div>{{ doc.attributes.lname }} {{ doc.attributes.fname }}</div>
                </div>
                <div v-if="doc.price" class="whitespace-nowrap">{{ programsStore.home ? "от" : ""}} {{ numberWithSpaces(doc.price) }} Р</div>
                <div v-else class="whitespace-nowrap">{{ programsStore.home ? "от" : ""}} {{ programsStore.programCostDefault ? numberWithSpaces(programsStore.programCostDefault) : "" }} ₽</div>
            </div>
        </div>

    </div>
</template>

<script>
import defaultPhoto from "@/assets/img/docs/placeholders/woman_1.png";
import { createDateText, createTimeText } from "./../../../../libs/date";
import { useSlots } from "./../../../../store/slots";
import { usePrograms } from "./../../../../store/programs";
import { useDocs } from "./../../../../store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot
} from "@headlessui/vue";

import { XIcon } from "@heroicons/vue/outline";
import TypeVisit from "./../../../Sections/TypeVisit";
import Contacts from "./../../../Slot/Contacts";
import { numberWithSpaces, scrollTo } from "./../../../../libs/ui";
import Actions from "./../../../Slot/Actions";
import { useClinics } from "./../../../../store/clinics";

export default {
    props: ["type", "docs"],
    setup() {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const programsStore = usePrograms();
        const clinicsStore = useClinics();

        return {
            numberWithSpaces,
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            scrollTo,
            defaultPhoto,
            programsStore
        };
    },

    data() {
        return {
            name: "Врач",
            clinicId: "",
            customProgramsByAges: null,
            listCustomProgramAges: null
        };
    },
    watch: {
        date() {
        }
    },
    components: {
        Actions,
        Contacts,
        Dialog,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        DialogOverlay,
        Switch,
        SwitchGroup,
        SwitchLabel,
        XIcon,
        TypeVisit
    },
    methods: {
        selectDoc(id) {
            this.programsStore.leadDoc = id;
            const price = this.docsList.find(doc => doc.id === id).price
            if (price) {
                this.programsStore.programCost = price;
            } else {
                this.programsStore.programCost = this.programsStore.programCostDefault;
            }
        },
        declOfNum(number, words) {
            return words[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
                ];
        }
    },
    async mounted() {
        this.clinicId = this.clinicsStore.clinicId;

        this.customProgramsByAges = this.programsStore.customProgramsByAges;
        this.listCustomProgramAges = this.programsStore.listCustomProgramAges;

        this.selectedItem = this.listCustomProgramAges[this.selectedIndex];
    },
    computed: {
        docsList() {
            return this.programsStore.programDocs.filter(doc => {
                return this.docsStore.docsByClinic.findIndex(docTmp => {
                    return doc.id === docTmp.id;
                }) !== -1;
            }).reverse();
        },
        cart() {
            return Object.values(this.programsStore.cartDetails);
        },
        inCartTitles() {
            return Object.keys(this.programsStore.cartDetails);
        },
        cartJSON() {
            return JSON.stringify(this.programsStore.cartDetails);
        },
        counterText() {
            const text = this.declOfNum(this.programsStore.totalCount, [
                "позиция",
                "позиции",
                "позиций"
            ]);
            return `${this.programsStore.totalCount} ${text}`;
        }
    }
};
</script>
