<template>
    <div class="space-y-4 px-4 py-2 sm:px-8 mb-8">
        <div class="mb-5 flex flex-col justify-center items-center ">
            <div class="text-stone-700 w-full opacity-60 mb-2">
                Комплексная годовая программа для ребенка <span v-if="BasketData">{{ BasketData.title }}</span>
            </div>

            <div class="flex flex-wrap gap-2 w-full justify-between border-gray-300 border rounded-md p-4 mb-4">
                <div class="">
                    <p class="m-0">Итоговая стоимость</p>
                    <p class="m-0 text-pink-600 max-w-[235px] text-sm">
                        включена дополнительная скидка 10% на амбулаторные услуги педиатрии в период действия программы
                    </p>
                </div>
                <div class="block">
                    <div class="text-xl leading-8">{{ numberWithSpaces(programsStore.totalSum) }} ₽</div>
                    <div class="text-sm text-grey-600">
                        {{ counterText }}
                    </div>
                </div>
            </div>

        </div>
        <div v-if="BasketData">
            <CardProgramBasketCustom
                    :programKey="BasketData.key"
                    :clinicId="clinicId"
                    :basket="true"
                    :doctorsList="BasketData.docs"
                    :sum="programsStore.totalSum"
            />
        </div>
    </div>

</template>

<script>
import {createDateText, createTimeText} from "./../../../../libs/date";
import {useSlots} from "./../../../../store/slots";
import {usePrograms} from "./../../../../store/programs";
import {useDocs} from "./../../../../store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

import {XIcon} from "@heroicons/vue/outline";
import TypeVisit from "./../../../Sections/TypeVisit";
import Contacts from "./../../../Slot/Contacts";
import {numberWithSpaces, scrollTo} from "./../../../../libs/ui";
import Actions from "./../../../Slot/Actions";
import {useClinics} from "./../../../../store/clinics";

export default {
    props: ["type"],
    setup(props) {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const programsStore = usePrograms();
        const clinicsStore = useClinics();

        return {
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            scrollTo,
            programsStore,
            numberWithSpaces
        };
    },

    data() {
        return {
            name: "Врач",
            clinicId: "",
            customProgramsByAges: null,
            listCustomProgramAges: null,
        };
    },
    watch: {
        date() {
        },
    },
    components: {
        Actions,
        Contacts,
        Dialog,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        DialogOverlay,
        Switch,
        SwitchGroup,
        SwitchLabel,
        XIcon,
        TypeVisit,
    },
    methods: {
        numberWithSpaces,
        declOfNum(number, words) {
            return words[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
                ];
        },
    },
    async mounted() {
        this.clinicId = this.clinicsStore.clinicId;
        this.customProgramsByAges = this.programsStore.customProgramsByAges;
        this.listCustomProgramAges = this.programsStore.listCustomProgramAges;

        this.selectedItem = this.listCustomProgramAges[this.selectedIndex];
    },
    computed: {
        BasketData() {
            for (let item in this.listCustomProgramAges) {
                for (let key in Object.keys(this.programsStore.customProgramsList)) {
                    if (this.programsStore.customProgramsList[key].attributes.ages.data[0].attributes.title === this.listCustomProgramAges[item].title) {
                        return {
                            title: this.listCustomProgramAges[item].title,
                            key,
                            docs: this.programsStore.customProgramsList[key].attributes.docs
                        }
                    }
                }
            }
            return null;
        },
        cart() {
            return Object.values(this.programsStore.cartDetails);
        },
        inCartTitles() {
            return Object.keys(this.programsStore.cartDetails);
        },
        cartJSON() {
            return JSON.stringify(this.programsStore.cartDetails);
        },
        counterText() {
            const text = this.declOfNum(this.programsStore.totalCount, [
                "позиция",
                "позиции",
                "позиций",
            ]);
            return `${this.programsStore.totalCount} ${text}`;
        },
    },
};
</script>
