<template>
    <div>
        <div
                v-for="(service, key) in programsStore.customProgramsList[programKey]?.attributes.Combo"
                :key="service.id"
                class="mb-8"
        >
            <div
                    class="uppercase mb-4 text-mini"
                    v-show="programsStore.checkIncludeChecked(service)"
            >
                {{ service.title }}
            </div>
            <div
                    class="grid gap-2"
                    v-show="programsStore.checkIncludeChecked(service)"
            >

                <fieldset
                        class="flex"
                        v-for="(serviceItem, key2) in service?.options.data"
                        :key="serviceItem.id"
                        v-show="serviceItem.attributes?.checked"
                >
                    <div
                            class="flex items-center justify-between bg-white w-full rounded-md border-gray-100 border rounded-md p-2"
                    >
                        <label
                                class="flex items-center w-full cursor-pointer text-sm font-medium text-gray-700"
                        >
                            <div
                                    class="h-6 w-6 min-w-[1.5rem] relative before:block before:absolute before:top-[50%] before:right-[50%] before:bottom-[50%] before:left-[50%] before:translate-y-[-50%] before:translate-x-[-50%] hidden"
                                    :class="{
                                        'before:w-2 before:h-2 before:bg-white before:rounded-full ': getInputType(service.__component) === 'radio',
                                        'before:w-3 before:h-2 before:bg-tick before:bg-contain before:bg-center before:bg-no-repeat': getInputType(service.__component) === 'checkbox',
                                    }"
                            >
                                <input
                                        :type="getInputType(service.__component)"
                                        :name="service.title"
                                        :id="serviceItem.id"
                                        class="w-full h-full border border-[#EBE4E3] appearance-none focus:accent-pink-600 checked:bg-pink-600 checked:border-pink-600"
                                        @click="
                                            programsStore.setServiceChecked(
                                            programKey,
                                            key,
                                            key2,
                                            service.__component
                                        )"
                                        :checked="
                                        programsStore.customProgramsList[programKey].attributes.Combo[key].options.data[key2].attributes?.checked
                                        "
                                        :class="{
                                            'rounded-full checked:border-pink-600':getInputType(service.__component) === 'radio',
                                            'rounded focus:accent-pink-600 checked:bg-pink-600 checked:border-pink-600':getInputType(service.__component) === 'checkbox',
                                        }"
                                />
                            </div>

                            <span
                                    class="mr-auto select-none text-base"
                                    v-if="serviceItem.attributes?.title"
                            >
                                {{ serviceItem.attributes?.title }}
                            </span>
                            <span
                                    class="text-grey-600 text-sm ml-3 select-none whitespace-nowrap"
                                    v-if="serviceItem.attributes.price"
                            >
                                {{ priceFormat(serviceItem.attributes.price) }} ₽
                            </span>
                        </label>
                    </div>
                </fieldset>
            </div>
        </div>

        <div class="mt-6" v-if="docsList.length">
            <div class="mb-2">Выберите ведущего врача</div>

            <template v-for="(doc, idx) of docsList">
                <div class="flex flex-wrap items-center border  rounded-md mb-2 p-2 cursor-pointer justify-between"
                     :class="[doc.id !== programsStore.leadDoc ? 'border-gray-300' : 'border-pink-300', doc.minCost > sum ? 'bg-gray-300 opacity-30 cursor-no-drop' : '']"
                     @click="setLeadDoc(doc.id, doc.minCost)"
                >
                    <div
                            class="mr-2 rounded-full w-12 h-12 max-w-12 overflow-hidden  inline-block"
                    >
                        <img
                                v-if="doc?.attributes?.photo_mini?.data?.attributes?.url"
                                :src="`https://admin.fomin-kids.ru${doc.attributes.photo_mini.data.attributes.url}`"
                                :alt="`${doc.attributes.fname} ${doc.attributes.lname}`"
                                :title="`${doc.attributes.fname} ${doc.attributes.lname}`"
                                class="w-full h-full object-cover object-top"/>
                        <img
                                v-else
                                :src="docsStore.getPhotoPlaceholder(doc)"
                                class="w-full h-full object-cover object-top"/>
                    </div>
                    <div class="inline-block">
                        {{ doc.attributes.lname }} {{ doc.attributes.fname }}
                    </div>
                    <div class="inline-block ml-auto pr-2">
                        <template v-if="doc.minCost && doc.minCost > sum">
                            от {{ numberWithSpaces(doc.minCost) }} ₽
                        </template>
                        <template></template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {Switch} from "@headlessui/vue";
import {usePrograms} from "./../../../../store/programs";
import {useDocs} from "~/store/docs";
import {useClinics} from "~/store/clinics";
import {numberWithSpaces} from "~/libs/ui";

export default {
    async setup(props) {
        const docsStore = useDocs();
        const programsStore = usePrograms();
        const clinicStore = useClinics();
        const cart = programsStore.cart;

        return {
            cart,
            programsStore,
            docsStore,
            clinicStore
        };
    },
    props: ["programKey", "clinicId", "basket", "doctorsList", "sum"],
    data() {
        return {enabled: false, short: false};
    },
    computed: {
        docsList() {
            const program = this.programsStore.customProgramsList[this.programKey]
            let docs = [];

            if (program && program.attributes.Combo) {
                Object.keys(program.attributes.Combo).forEach((key) => {
                    if (program.attributes.Combo[key].isGeneral) {
                        if (program.attributes.Combo[key].options) {
                            Object.keys(program.attributes.Combo[key].options.data).forEach((key2) => {
                                if (program.attributes.Combo[key].options.data[key2].attributes.checked) {
                                    docs = program.attributes.Combo[key].options.data[key2].attributes.docs;
                                }
                            });
                        }
                    }
                });
            }

            console.log(docs)

            return docs.filter((doc) => {
                    console.log(this.docsStore.docsByClinic.findIndex(docTmp => doc?.id === docTmp.id));
                    console.log(this.docsStore.docsByClinic)
                    return this.docsStore.docsByClinic.findIndex(docTmp => doc?.id === docTmp.id) !== -1
                }
            ).map(doc => {
                const docTmp = this.docsStore.getDocById(doc?.id);
                docTmp.minCost = doc.minCost;
                return docTmp;
            });
        }
    },
    components: {
        Switch,
    },
    methods: {
        numberWithSpaces,
        setLeadDoc(id, minCost) {
            if (minCost <= this.sum || !minCost) {
                this.programsStore.leadDoc = id;
            }
        },
        priceFormat(val) {
            return val.toLocaleString();
        },
        getInputType(name) {
            switch (name) {
                case "pages.one":
                    return "radio";
                    break;
                case "pages.multi":
                    return "checkbox";
                    break;
                default:
                    return "radio";
            }
        },
    },

};
</script>

<style lang="scss" scoped>
.program_active {
  border: 1px solid #d4026e;
  margin-top: -24px;
  margin-bottom: -24px;
  padding-top: 64px;
  padding-bottom: 64px;
  @media screen and (max-width: 1023px) {
    margin: 0px;
    padding: 20px 20px;
  }
}
</style>
